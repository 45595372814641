import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./SongRequests.css";
import ReturnToHomeButton from "./ReturnToHome";

const SongRequests = ({ user, isLoggedIn }) => {
    const [songs, setSongs] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);
    const [username, setUsername] = useState("");
    const [specialInstructions, setSpecialInstructions] = useState("");
    const [queue, setQueue] = useState([]);

    useEffect(() => {
        console.log("User:", user);
        console.log("Is Logged In:", isLoggedIn);
        
        const fetchSongs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/songreq`);
                setSongs(response.data);
            } catch (error) {
                console.error("Error fetching songs:", error);
                toast.error("Failed to fetch songs.");
            }
        };
    
        const fetchQueue = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/songrequests`);
                setQueue(response.data);
            } catch (error) {
                console.error("Error fetching queue:", error);
                toast.error("Failed to fetch the queue.");
            }
        };
    
        fetchSongs();
        fetchQueue();
    
        if (isLoggedIn && user) {
            setUsername(user.username); // Auto-fill the username for logged-in users
        }
    }, [isLoggedIn, user]);
    

    const handleSongChange = (selectedOption) => {
        setSelectedSong(selectedOption);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleSpecialInstructionsChange = (e) => {
        setSpecialInstructions(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the username is already in the queue
        if (queue.some(request => request.user_name.toLowerCase() === username.toLowerCase())) {
            toast.warn("You already submitted, please wait until we play your song before requesting again.");
            return;
        }
    
        // Check if the selected song is already in the queue
        if (queue.some(request => request.song_id === selectedSong.value)) {
            toast.warn("The song you selected is already in the queue! Please select a different song.");
            return;
        }
    
        // Submit the request
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/songrequests`, {
                song_id: selectedSong.value,
                user_name: username,
                special_instructions: specialInstructions, // Add special instructions if logged in
            });
    
            // Fetch the song title directly after the request is submitted
            const songTitle = songs.find(song => song.id === selectedSong.value)?.title;
    
            setQueue([...queue, { id: response.data.id, song_id: selectedSong.value, user_name: username, title: songTitle, special_instructions: specialInstructions }]);
            setSelectedSong(null); // Clear the selected song
            setSpecialInstructions(""); // Clear special instructions field
            toast.success("Request submitted successfully!");
        } catch (error) {
            if (error.response && error.response.status === 429) {
                toast.error("You've already submitted a request, please wait 1 minute before submitting again.");
            } else {
                console.error("Error submitting request:", error);
                toast.error("Failed to submit request. Please try again later.");
            }
        }
    };

    // Custom styles for react-select
    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "grey",
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
        }),
    };

    return (
        <div className="song-requests">
            <ToastContainer position="top-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <div>
    {/* Other component content */}
    {/* <ReturnToHomeButton /> */}
</div>

            <h1>Twitch Song Requests</h1>

            {isLoggedIn && <p>Logged in as {username}</p>}

            <form onSubmit={handleSubmit}>
                <div className="form-group song-select-container">
                    <label htmlFor="song-select">Select a Song:</label>
                    <Select
                        id="song-select"
                        options={songs.map(song => ({ value: song.id, label: song.title }))}
                        value={selectedSong}
                        onChange={handleSongChange}
                        placeholder="Type or select a song..."
                        styles={customStyles} // Apply the custom styles
                    />
                </div>

                {!isLoggedIn && (
                    <div className="form-group">
                        <label htmlFor="username">Your Name:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                    </div>
                )}

                {isLoggedIn && (
                    <div className="form-group">
                        <label htmlFor="special-instructions">Special Instructions:</label>
                        <input
                            type="text"
                            id="special-instructions"
                            value={specialInstructions}
                            onChange={handleSpecialInstructionsChange}
                        />
                    </div>
                )}

                <button type="submit" className="submit-button">Submit Request</button>
            </form>

            <h2>Current Queue</h2>
            <ul className="queue-list">
                {queue.map((request, index) => (
                    <li key={index}>
                        {request.title || songs.find(song => song.id === request.song_id)?.title} - Requested by {request.user_name}
                        {request.special_instructions && <span style={{ color: 'red' }}> - Special Instructions: {request.special_instructions}</span>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SongRequests;
