import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Home from './components/Home';
import SearchResults from './components/SearchResults';
import DBEntry from './components/dbentry';
import "./App.css";
import CSVote from './components/CSVote';
import NukeVotes from './components/NukeVotes';
import SongRequests from './components/SongRequests';
import RequestAdmin from './components/RequestAdmin'; 
import NavPage from './components/NavPage';
import ToS from './components/tos';
import CSVote2 from './components/CSVote2';
import LiveWidget from './components/LiveWidget';
import Register from './components/Register';
import Gacha from './components/Gacha'; // Import the Gacha component
import GachaAdminTools from './components/GachaAdminTools'; // Import GachaAdminTools component

const App = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');

    if (storedToken && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const handleSearch = async (searchParams) => {
    try {
      const query = new URLSearchParams(searchParams).toString();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/songs?${query}`);
      const data = await response.json();
      setSearchResults(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching songs:', error);
      setSearchResults([]);
    }
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path={process.env.REACT_APP_DB_ENTRY_PATH} element={<DBEntry />} />
          <Route path="/songsearch" element={<><Home onSearch={handleSearch} /><SearchResults results={searchResults} /></>} />
          <Route path="/csvote" element={<CSVote2 />} />
          <Route path={process.env.REACT_APP_NUKE_VOTES_PATH} element={<NukeVotes />} />

          {/* Pass isLoggedIn and username to SongRequests */}
          <Route 
            path="/songrequests" 
            element={<SongRequests user={{ username }} isLoggedIn={isLoggedIn} />} 
          />

          <Route path={process.env.REACT_APP_ADMIN_TOOLS_PATH} element={<RequestAdmin />} />
          {/* <Route path="/" element={<NavPage />} /> */}
          <Route path="/" element={<SongRequests />} />
          <Route path="/tos" element={<ToS />}/>
          <Route path="/livewidget" element={<LiveWidget/>}/>
          <Route path="/register" element={<Register />} />

          {/* Add Gacha page, only accessible if logged in */}
          
           <Route path="/gacha" element={<Gacha user={{ username }} isLoggedIn={isLoggedIn} />} />
          


          {/* Add GachaAdminTools page hidden behind .env variable */}
          
          <Route path="/gachaadmintools" element={<GachaAdminTools />} />

          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
