import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RequestAdmin.css';

const RequestAdmin = () => {
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    const fetchQueue = async () => {
      try {
        const response = await axios.get(`https://twitchdb-production.up.railway.app/api/requests`);
        setQueue(response.data);
      } catch (error) {
        console.error('Error fetching queue:', error);
      }
    };

    // Fetch the initial queue
    fetchQueue();

    // Refresh the queue every 10 seconds
    const interval = setInterval(fetchQueue, 10000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://twitchdb-production.up.railway.app/api/requests/${id}`);
      toast.success('Song deleted from the queue.');
      setQueue((prevQueue) => prevQueue.filter((request) => request.id !== id));
    } catch (error) {
      console.error('Error deleting request:', error);
      toast.error('Failed to delete the song.');
    }
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete(`https://twitchdb-production.up.railway.app/api/requests`);
      toast.success('All songs deleted from the queue.');
      setQueue([]);
    } catch (error) {
      console.error('Error deleting all requests:', error);
      toast.error('Failed to clear the queue.');
    }
  };

  return (
    <div className="request-admin">
      <h1>Song Queue Admin</h1>
      <button className="delete-all-button" onClick={handleDeleteAll}>Clear Queue</button>
      <ul className="queue-list">
        {queue.map((request) => (
          <li key={request.id} className="queue-item">
            <span>{request.song} - Requested by {request.username}</span>
            <button className="delete-button" onClick={() => handleDelete(request.id)}>Delete</button>
          </li>
        ))}
      </ul>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default RequestAdmin;
