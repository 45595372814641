import React, { useState, useEffect } from 'react';
import './LiveWidget.css';

const LiveWidget = () => {
  const [queue, setQueue] = useState([]);
  const [songrequestsEnabled, setSongrequestsEnabled] = useState(true);

  useEffect(() => {
    const fetchQueue = async () => {
      try {
        const response = await fetch('https://twitchdb-production.up.railway.app/api/requests');
        const data = await response.json();
        setQueue(data);
      } catch (error) {
        console.error('Error fetching queue:', error);
      }
    };

    // Fetch the initial queue
    fetchQueue();

    // Refresh the queue every 10 seconds
    const interval = setInterval(fetchQueue, 10000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Limit the queue to the current song and the next song
  const currentSong = queue[0];
  const nextSong = queue[1];

  return (
    <div className="live-widget">
      <div className="queue-section">
        <h2>
          <span style={{ color: songrequestsEnabled ? 'green' : 'red' }}>
            {songrequestsEnabled ? 'Requests Open' : 'Requests Closed'}
          </span>
          {' '}
          Queue ({queue.length} songs left)
        </h2>
        <ul>
          {currentSong && (
            <li>
              <strong style={{ color: 'aqua' }}>CURRENT SONG:</strong>
              <span style={{ color: 'white' }}> {currentSong.song} - Requested by {currentSong.username}</span>
            </li>
          )}
          {nextSong && (
            <li>
              <strong style={{ color: 'teal' }}>Next song:</strong>
              <span style={{ color: 'white' }}> {nextSong.song} - Requested by {nextSong.username}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LiveWidget;
